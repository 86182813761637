import React from 'react';

import {Route, Routes} from 'react-router-dom';

import HomePage from './pages/HomePage';

import BlogArchive from './pages/BlogArchive';

import logo from './logo.svg';

// import './App.css';
import './scss/application.scss';

export default function App() {

    return(
      <Routes>
        <Route exact path="/" element={ <HomePage/> }/>
        <Route exact path="/:id" element={ <BlogArchive/> }/>
      </Routes>
    );

}

function componentDidMount () {
    const script = document.createElement("script");
    script.src = "loading-animation.js";
    script.async = true;
    document.body.appendChild(script);
}
