import React, {Component} from "react";

import MenuButton from "../components/MenuButton";

function TopNav(props){
    return(
      <div className="combc-top-nav">
        <div className="combc-top-nav__logo">
          <a href="">Check Out My Bug Collection</a>
        </div>
        <div className="combc-top-nav__menu">
          <MenuButton/>
          <ul className="combc-top-nav__menu__list">
            <li>Home</li>
            <li>Music</li>
            <li>Shows</li>
            <li>Blog</li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    );
}

export default TopNav;
