import React, {Component} from "react";

function PageHeader(props){
  return(
    <div className="combc-page-header">
      <img src={ props.header_image }/>
      <h1>{ props.title }</h1>
    </div>
  );
}

export default PageHeader;
