import React from "react";

import { Link } from 'react-router-dom';
import Header from "../components/Header";
import HomeBanner from "../components/HomeBanner";
import MediaBody from "../components/MediaBody";
import UpcomingShows from "../components/UpcomingShows";
import BandBio from "../components/BandBio";
import Footer from "../components/Footer";

import background from '../img/redbluecombc.jpg';
import cover from '../img/things-are-happening-cover.jpg';
import bio_image from '../img/good-press-photo-website-draft-1.jpg';
import poster from '../img/feb-27-23-show-poster.jpg';

function HomePage(){
  return(

    <div className="combc-wrapper">

      <HomeBanner background={background}/>

      <MediaBody cover={cover}/>

      <BandBio image={bio_image} description="Check Out My Bug Collection are an electronic duo from Seattle, Washington. Cody Thompson and Jeff Bass used to be in a band called NUDE POP."/>

      <Footer />

    </div>
  )
}

export default HomePage;
