import React, {Component} from "react";

function BlogBody(props){
  return(

    <div className="combc-blog">
      <div className="combc-row combc-row-4-8">
        <div className="combc-row__inner combc-blog__inner">
          <div className="combc-column">
            <div className="combc-archive">
              <h3>Blog Archive</h3>
              <ul className="combc-archive__list">
                <li>2020
                  <ul>
                    <li>April</li>
                    <li>June</li>
                  </ul>
                </li>
                <li>2021
                  <ul>
                    <li>April</li>
                    <li>May</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="combc-column">
              <div className="combc-blog__body">
                <hr />
                <p><a href="" className="combc-blog__body__author">{ props.author }</a> | <span className="combc-blog__body__date">{ props.date }</span></p>
                <p>Since the lowest note you can assign to a key in the <a href="#">12 step editor</a> is
                C2 and the SP-404SX is by default set up to have Bank A Pad 1 mapped to B2,
                I think I need to set the MIDI channel</p>
                <p>I was having trouble editing the presets on the 12 step with the 12
                Step Editor software, as I was just seeing a blank list in the presets
                dropdown. After doing some digging, I realized this was because I had
                the 12 Step Editor program in the Applications folder, but needed to
                move the actual program file out of the “12_Step_Editor_Mac_V2.0” folder.
                After moving the program file, I saw all of the presets.</p>
                <p>In order to create a new preset, I started with the basic Chromatic
                Scale preset and clicked “Save As,” giving it a new name. I also changed
                the display name and saved. After that, I went to the “Setlist” tab, set
                the pre-loaded “Chromatic Scale” preset to slot 58 (the first empty slot),
                and dropped my custom preset “COBC” in slot 1.</p>
                <p>To enable control of all ten banks of samples on the SP404, I decided
                it made the most sense to set up two presets: one to control channel 1,
                which by SP404 defaults controls banks A-E, and another to control
                channel 2, which controls banks F-J.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default BlogBody;
