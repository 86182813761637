import React, {Component} from "react";

export default class MenuButton extends React.Component{

    // constructor(props) {
    //     super(props);
    //     this.addActiveClass= this.addActiveClass.bind(this);
    //     this.state = {
    //         active: false,
    //     };
    // }
    //
    // toggleClass() {
    //     const currentState = this.state.active;
    //     this.setState({ active: !currentState });
    // }
    //
    // componentDidMount() {
    //     // this.saySomething("component did mount");
    // }


    // render(){
    //   return (
    //     <div className={`combc-top-nav__menu__button ${this.state.active ? "open" : null}`}
    //             onClick={this.toggleClass}>
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //     </div>
    //   );
    // }
    render(){
      return (
        <div className="combc-top-nav__menu__button">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      );
    }

}
