import React, {Component} from "react";

function BandBio(props){
  return(
    <div className="combc-bio">
      <div className="combc-row combc-row-6-6">

        <div className="combc-row__inner">
          <div className="combc-column">
            <img src={ props.image }/>
          </div>
          <div className="combc-column">
            <p>{ props.description }</p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default BandBio;
