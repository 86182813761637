import React from "react";
import PageHeader from "../components/PageHeader";
import TopNav from "../components/TopNav";
import BlogBody from "../components/BlogBody";
import Footer from "../components/Footer";

import header_image from '../img/12-step.jpg';
function BlogArchive(){
  return(

    <div className="combc-wrapper">

      <TopNav/>

      <PageHeader title="The 12-Step" header_image={header_image}/>

      <BlogBody author="Jeff" date="7/14/2022"/>

      <Footer
      description="Check Out My Bug Collection are an electronic duo from Seattle, Washington. Cody Thompson and Jeff Bass used to be in a band called NUDE POP."/>

    </div>
  )
}

export default BlogArchive;
