import React, {Component} from "react";

function Footer(props){
  return(
    <div className="combc-footer">
      <div className="combc-row combc-row-12">
        <div className="combc-row__inner">
            <div className="combc-column combc-footer__column ">
              <div className="combc-footer__social-icons">
                <a href="https://open.spotify.com/artist/6g0aplhyPdqn9N1AyVYLjM?si=BaPbGgRJStuI7gYteKCDrg" target="_blank" rel="noreferrer"><img src="img/spotify.svg"/></a>
                <a href="https://music.apple.com/us/artist/check-out-my-bug-collection/1652140403" target="_blank" rel="noreferrer"><img src="img/apple.svg"/></a>
                <a href="https://www.youtube.com/channel/UCJ91cneXjyVamroGiJ-YPXA" target="_blank" rel="noreferrer"><img src="img/youtube.svg"/></a>
                <a href="https://www.instagram.com/combcband/" target="_blank" rel="noreferrer"><img src="img/instagram.svg"/></a>
                <a href="https://www.facebook.com/combcband" target="_blank" rel="noreferrer"><img src="img/facebook.svg"/></a>
                <a href="https://twitter.com/combcband" target="_blank" rel="noreferrer"><img src="img/twitter.svg"/></a>
              </div>
            <p>Booking inquiries:  <a href="mailto:combcbooking@gmail.com">combcbooking@gmail.com</a></p>

            <p className="combc-footer__copyright">Copyright 2022 COMBC</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
