import React, {Component} from "react";

function HomeBanner(props){

  return(

    <div className="combc-home-banner" style={{
          backgroundImage: `url(${props.background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
      <div className="combc-home-banner__inner">
        <h1>Check Out My Bug Collection</h1>
        <h1>Check Out My Bug Collection</h1>
        <h1>Check Out My Bug Collection</h1>
      </div>
    </div>
  );
}

export default HomeBanner;
