import React, {Component} from "react";

function MediaBody(props){
  return(
    <div className="combc-media">
      <div className="combc-row combc-row-6-6">
        <div className="combc-row__inner">
          <div className="combc-column">
            <div className="combc-audio">
              <h3>New single out now</h3>
              <h2>Things Are Happening</h2>
              <div className="combc-audio__inner">
                <a className="combc-audio__link" target="_blank" href="https://open.spotify.com/album/5FvLDFBFd9PDRdoSlbwPtk?referral=labelaffiliate&utm_source=1011lwmxTHta&utm_medium=Indie_Distrokid&utm_campaign=labelaffiliate">
                  <img src="img/spotify.svg" />
                  <span className="combc-audio__link__text">Spotify</span>
                </a>
                <a className="combc-audio__link" target="_blank" href="https:&#47;&#47;music.apple.com&#47;us&#47;album&#47;things-are-happening-single&#47;1652142978?uo&#61;4">
                  <img src="img/apple.svg"/>
                  <span className="combc-audio__link__text">Apple Music</span>
                </a>
                <a className="combc-audio__link" target="_blank" href="https:&#47;&#47;music.apple.com&#47;us&#47;album&#47;things-are-happening-single&#47;1652142978?uo&#61;4&amp;app&#61;itunes&amp;at&#61;1001lry3&amp;ct&#61;dashboard">
                  <img src="img/apple.svg"/>
                  <span className="combc-audio__link__text">iTunes</span>
                </a>
                <a className="combc-audio__link" target="_blank" href="https://youtu.be/3l0lXhQdzqA">
                  <img src="img/youtube.svg"/>
                  <span className="combc-audio__link__text">YouTube</span>
                </a>
              </div>
            </div>
          </div>
          <div className="combc-column">
            <img src={ props.cover }/>
          </div>
        </div>
      </div>
      <div className="combc-row combc-row-12">
        <div className="combc-row__inner">
          <iframe src="https://www.youtube.com/embed/3l0lXhQdzqA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

    </div>
  );
}

export default MediaBody;
